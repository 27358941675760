import { initGqlClient } from '@shared/utils/graphQl';
import { isIntegrationTest } from '@shared/utils/integrationTestUtils';

import { initLocoGqlClient } from 'containers/Loco/locoGQlClient';

import { updateUserData, clearUserData } from '../actions';

import umAuthClient from './UMAuthentication';

let umClient = umAuthClient;
let gqlClient;

if (isIntegrationTest()) {
  /* eslint-disable-next-line global-require */
  umClient = require('../testing/UMAuthenticationMock');
}

const addGqlTokenToHeaders = (token) => {
  if (gqlClient) {
    gqlClient.setHeaders({
      authorization: `Bearer ${token}`,
    });
  }
};

const updateUMToken = ({ response, dispatch }) => {
  const { umToken, umPermissions } = umClient.extractRefreshToken(response);
  dispatch(
    updateUserData({
      umToken,
      umPermissions,
    }),
  );
  addGqlTokenToHeaders(umToken);
};

const initGqlErrorHandler = ({ error, handleSignOut }) => {
  if (error.response?.status === 401) {
    return handleSignOut();
  }
  throw error;
};

export const handleHealthySignOut = (dispatch) => {
  addGqlTokenToHeaders('');
  dispatch(clearUserData());
};

export const handleHealthySignIn = async ({
  idToken,
  userInfo,
  setAuthError,
  handleSignOut,
  dispatch,
}) => {
  const { email, name, avatarUrl } = userInfo;
  try {
    const { umToken, umPermissions } = await umClient.exchangeUMToken(idToken);
    gqlClient = initGqlClient(process.env.PRODUCTION_RUN.GRAPHQL_API_URL, {
      requestMiddleware: [(response) => updateUMToken({ response, dispatch })],
      errorHandler: (error) => initGqlErrorHandler({ error, handleSignOut }),
    });
    addGqlTokenToHeaders(umToken);
    initLocoGqlClient(idToken);

    dispatch(
      updateUserData({
        email,
        name,
        idToken,
        avatarUrl,
        umToken,
        umPermissions,
      }),
    );
  } catch (e) {
    console.error(e);
    setAuthError(true);
    handleSignOut();
  }
};
