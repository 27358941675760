import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import cc from 'classcat';

import { BrainAuthContext } from 'containers/App/BrainAuthProvider';

import signOut from 'images/side-bar/sign-out.svg';
import signOutActive from 'images/side-bar/sign-out-active.svg';
import archive from 'images/side-bar/archive.svg';
import archiveActive from 'images/side-bar/archive-active.svg';

import VersionDetail from './VersionDetail';
import classes from './SidebarFooter.scss';

export default function SidebarFooter({ isExpanded, collapseSidebar }) {
  const { handleSignOut } = useContext(BrainAuthContext);

  const location = useLocation();
  const isArchiveActive = location.pathname.startsWith('/archive');

  return (
    <>
      <Link
        className={cc([classes.link, { [classes.active]: isArchiveActive }])}
        to="/archive"
      >
        <img src={archive} alt="" />
        <img className={classes.activeImg} src={archiveActive} alt="" />
        Archive
      </Link>
      <button className={classes.button} type="button" onClick={handleSignOut}>
        <img src={signOut} alt="" />
        <img className={classes.activeImg} src={signOutActive} alt="" />
        Log out
      </button>
      <VersionDetail isExpanded={isExpanded} collapseSidebar={collapseSidebar} />
    </>
  );
}

SidebarFooter.propTypes = {
  collapseSidebar: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
};
