import { useEffect, useState } from 'react';

import useLoadScript from '@shared/hooks/useLoadScript';
import { isIntegrationTest } from '@shared/utils/integrationTestUtils';
import { reporter } from '@shared/reporter';

if (isIntegrationTest()) {
  /* eslint-disable-next-line global-require */
  require('../testing/GoogleAuthProviderMock');
}

function useGoogleInit(handleSignIn) {
  const isTest = isIntegrationTest();
  const [isGoogleInitialized, setIsGoogleInitialized] = useState(false);
  const [isGoogleError, setIsGoogleError] = useState(false);

  // Load Google Identity Services (GIS) client
  const { isLoadingScript: gisLoading, errorLoadingScript: gisError } = useLoadScript(
    process.env.GOOGLE_GIS_CLIENT_URL,
  );
  // Load google API client
  const { isLoadingScript: gapiLoading, errorLoadingScript: gapiError } = useLoadScript(
    process.env.GOOGLE_API_CLIENT_URL,
  );

  useEffect(() => {
    if (gisLoading || !window.google) return;

    if (gisError) {
      setIsGoogleError(true);
      return;
    }
    // Initializes the Google Identity Services (GIS) for authentication
    window.google.accounts.id.initialize({
      client_id: process.env.GOOGLE_API_CLIENT_ID,
      use_fedcm_for_prompt: true,
      callback: handleSignIn,
    });
  }, [gisLoading, gisError]);

  useEffect(() => {
    if (gapiLoading || !window.gapi) return;

    if (gapiError) {
      setIsGoogleError(true);
      return;
    }

    // Initialize the Google API client - enable access to drives apis etc.
    window.gapi.load('client', async () => {
      try {
        await gapi.client.init({
          apiKey: process.env.GOOGLE_API_KEY,
          discoveryDocs: process.env.GOOGLE_API_DISCOVERY_DOCS,
        });
        setIsGoogleInitialized(true);
      } catch (error) {
        setIsGoogleError(true);
        reporter.error(error, { context: 'Failed to initialize Google API client' });
      }
    });
  }, [gapiLoading, gapiError]);

  return {
    isLoading: Boolean(gapiLoading || !isGoogleInitialized),
    isError: Boolean(gapiError || isGoogleError),
    isTest,
  };
}

export default useGoogleInit;
