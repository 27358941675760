import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

// Production Runs
import PRListPage from 'containers/PRListPage/Loadable';
import PRInitPage from 'containers/PRInitPage/Loadable';
import PRUpdatePage from 'containers/PRUpdatePage/Loadable';
import PRInstancePage from 'containers/PRInstancePage/Loadable';
import StepPage from 'containers/StepPage/Loadable';
// Analytics
import Analytics from 'containers/Analytics/Loadable';
// Colorboard
import ColorboardQCPage from 'containers/MeColorboard/ColorboardQCPage/Loadable';
import QRSheetsPage from 'containers/MeColorboard/QRSheetsPage/Loadable';
import LotsList from 'containers/MeColorboard/LotsList/Loadable';
import LotPage from 'containers/MeColorboard/LotPage/Loadable';
import ScanPage from 'containers/MeColorboard/ScanPage/Loadable';
import CPSPage from 'containers/MeColorboard/CPSPage';
import LotCorrectionsPage from 'containers/MeColorboard/LotCorrectionsPage';
// Loco
import LocoLocationsList from 'containers/Loco/LocoLocationsList/Loadable';
import LOCOLocationPage from 'containers/Loco/LocoLocationPage';
// Open Tasks
import OpenTasks from 'containers/OpenTasks/Loadable';
// Archive
import Archive from 'containers/Archive/ArchivePage/Loadable';

import PermissionRoute from 'components/PermissionRoute';
import Layout from 'components/Layout';

import ProcessRoutes from './ProcessRoutes';
import UserManagementRoutes from './UserManagementRoutes';

// Main page
const RedirectToTempMain = () => <Redirect to="/production-runs" />;
// 404
const NotFoundPage = () => <h2>404 Page Not Found</h2>;

function AuthenticatedApp() {
  const { pathname } = useLocation();
  const isStepPage = !!pathname.match(/\/step\//i);

  const processPathMatch = pathname.match(/\/process\/([A-Za-z-]+)/);
  const processType = processPathMatch && processPathMatch[1];

  const userManagementMatch = pathname.match(/\/(users|user)\/([A-Za-z-]+)/);
  const domain = userManagementMatch && userManagementMatch[2];

  // NOTE: If you change the routes, make sure you update them in the SideBar and Links as well

  return (
    <Layout hasLogDrawer={isStepPage}>
      <OpenTasks />
      <Switch>
        <Route exact path="/" component={RedirectToTempMain} />
        <Route exact path="/production-runs" component={PRListPage} />
        <PermissionRoute
          exact
          path="/production-run/new"
          component={PRInitPage}
          scope="production-run:create"
        />
        <PermissionRoute
          exact
          path="/production-run/edit/:processId"
          component={PRUpdatePage}
          scope="production-run:update"
        />
        <Route exact path="/production-run/:processId" component={PRInstancePage} />
        <Route
          exact
          path="/production-run/:processId/stage/:stageId/step/:stepId"
          component={StepPage}
        />

        {/* LOCO */}
        <Route exact path="/loco" component={LocoLocationsList} />
        <PermissionRoute
          exact
          path="/loco/locations/new"
          component={LOCOLocationPage}
          scope="loco:create"
        />
        <Route
          exact
          path="/loco/locations/edit/:locationId"
          component={LOCOLocationPage}
        />

        {/* QMS */}
        {ProcessRoutes(processType)}
        {/* Analytics */}
        <PermissionRoute
          scope="tableau:list"
          exact
          path="/analytics/:productDomain?"
          component={Analytics}
        />

        {/* Colorboard */}
        <PermissionRoute
          scope="colorboard-qc:list"
          exact
          path="/colorboard"
          component={ColorboardQCPage}
        />
        <PermissionRoute
          scope="colorboard-sheets:list"
          exact
          path="/qr-sheets"
          component={QRSheetsPage}
        />
        <PermissionRoute
          scope="lot-corrections:list"
          exact
          path="/lot-corrections"
          component={LotCorrectionsPage}
        />
        <Route exact path="/cps" component={CPSPage} />
        <Route exact path="/lots/:clbProductType?" component={LotsList} />
        <Route exact path="/lots/:colorboardType/:lotId" component={LotPage} />
        <Route
          exact
          path="/lots/:colorboardType/:lotId/scans/:scanId"
          component={ScanPage}
        />
        {/* User Management */}
        {/* see https://dev.to/tylerlwsmith/how-to-get-around-react-router-4-not-allowing-nested-components-inside-of-a-switch-2hl */}
        {UserManagementRoutes(domain)}

        {/* Archive */}
        <Route exact path="/archive/:processType?" component={Archive} />
        {/* 404 */}
        <Route component={NotFoundPage} />
      </Switch>
    </Layout>
  );
}

export default AuthenticatedApp;
