import { Helmet } from 'react-helmet';

import logo from 'images/logo.svg';

import classes from './styles.scss';

export default function SignInPage() {
  return (
    <>
      <Helmet>
        <title>Sign in</title>
      </Helmet>
      <div className={classes.pageContainer}>
        <div className={classes.signInContainer}>
          <img className={classes.logo} src={logo} alt="Brain Logo" title="Brain App" />
          <div id="buttonDiv" />
        </div>
      </div>
    </>
  );
}
