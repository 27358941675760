import { useEffect } from 'react';

import SignInPage from 'components/SignInPage';

function UnauthenticatedApp() {
  useEffect(() => {
    if (!window.google) return;

    window.google.accounts.id.renderButton(document.getElementById('buttonDiv'), {
      theme: 'outline',
      size: 'large',
    });
  }, []);

  return <SignInPage />;
}

export default UnauthenticatedApp;
