import { hot } from 'react-hot-loader/root';
import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';

import { injectReducerAndSaga } from '@shared/redux/injectors/injectReducerAndSaga';

import FullPageLoader from 'components/FullPageLoader';

import reducer from './reducer';
import { handleHealthySignIn, handleHealthySignOut } from './authUtils/healthyAuthUtils';
import {
  handleGoogleAuthentication,
  handleGoogleAuthorization,
  handleGoogleSignOut,
} from './authUtils/googleAuthUtils';
import {
  ID_TOKEN_COOKIE,
  ID_TOKEN_EXPIRY_COOKIE,
  ACCESS_TOKEN_COOKIE,
  ACCESS_TOKEN_EXPIRY_COOKIE,
} from './authUtils/constants';
import useGoogleInit from './authUtils/useGoogleInit';

// Helper
const getUserInfoFromToken = (idToken) => {
  const { email, name, picture } = jwtDecode(idToken);
  return {
    email: email ?? '',
    name: name ?? '',
    avatarUrl: picture ?? '',
  };
};

export const BrainAuthContext = createContext('brainAuth');

const SignOutContext = createContext(() => {});

function BrainAuthProvider({
  authenticatedApp: AuthenticatedApp,
  unauthenticatedApp: UnauthenticatedApp,
}) {
  const [isGoogleSignedIn, setIsGoogleSignedIn] = useState(false);
  const [isHealthySignedIn, setIsHealthySignedIn] = useState(false);
  const [isGoogleServicesAuthorized, setIsGoogleServicesAuthorized] = useState(false);

  const [isAuthorizationPopupBlocked, setIsAuthorizationPopupBlocked] = useState(false);
  const [hasAuthError, setAuthError] = useState(false);
  const [userEmail, setUserEmail] = useState(null);

  const dispatch = useDispatch();

  const handleSignOut = () => {
    handleGoogleSignOut();
    handleHealthySignOut(dispatch);
    setIsGoogleSignedIn(false);
    setIsHealthySignedIn(false);
    setIsGoogleServicesAuthorized(false);
  };

  const handleSignIn = (googleSignInResponse) => {
    const idToken = googleSignInResponse.credential;
    if (!idToken) throw new Error('Failed to get google id token');

    const userInfo = getUserInfoFromToken(idToken);
    setUserEmail(userInfo.email);

    // Set the token value and expiration into the cookie
    handleGoogleAuthentication({ idToken, setIsGoogleSignedIn });
  };

  const {
    isTest,
    isLoading: isLoadingGoogleInit,
    isError: isGoogleInitError,
  } = useGoogleInit(handleSignIn);

  /* Id token is the google authentication token */
  const idToken = Cookies.get(ID_TOKEN_COOKIE);
  const idTokenExpiry = Cookies.get(ID_TOKEN_EXPIRY_COOKIE);

  /* once google is signed in, start healthy sign-in using the google id token */
  useEffect(() => {
    if (isHealthySignedIn) return;
    if (!isGoogleSignedIn) return;

    const userInfo = getUserInfoFromToken(idToken);

    try {
      handleHealthySignIn({
        idToken,
        userInfo,
        setAuthError,
        handleSignOut,
        dispatch,
      }).then(() => {
        setIsHealthySignedIn(true);
      });
    } catch (e) {
      console.error(e);
      setAuthError(true);
      handleHealthySignOut(dispatch);
      setIsHealthySignedIn(false);
    }
  }, [isGoogleSignedIn, isHealthySignedIn]);

  /* Access token is the google authorization token - access to google services (drive etc.) */
  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE);
  const accessTokenExpiry = Cookies.get(ACCESS_TOKEN_EXPIRY_COOKIE);

  /* once google and healthy are signed in, start google authorization */
  useEffect(() => {
    if (!window.gapi) return;
    if (isGoogleServicesAuthorized) return;
    if (!isGoogleSignedIn || !isHealthySignedIn) return;

    const isAccessTokenExpired = Date.now() >= Number(accessTokenExpiry);

    /* Handle state after refresh */
    // if we have a valid token, set it in the client and set the state to authorized
    if (accessToken && !isAccessTokenExpired) {
      gapi.client.setToken(JSON.parse(accessToken));
      setIsGoogleServicesAuthorized(true);
      return;
    }

    /* If there is no token or it's expired - request it silently */
    if (!isAuthorizationPopupBlocked) {
      handleGoogleAuthorization({
        userEmail,
        setIsGoogleServicesAuthorized,
        setIsAuthorizationPopupBlocked,
      });
      // when authorization popup is blocked, we will prompt the user with a button
    }
  }, [
    isGoogleSignedIn,
    isHealthySignedIn,
    isGoogleServicesAuthorized,
    isAuthorizationPopupBlocked,
    accessToken,
    accessTokenExpiry,
  ]);

  /* Handle state after refresh */
  useEffect(() => {
    if (isGoogleSignedIn) return;

    // If there is no token, it will be handled in the sign-in process
    if (!idToken) return;
    // if we have a valid token, set the state to signed in, otherwise sign out
    const isIdTokenExpired = Date.now() >= Number(idTokenExpiry);
    if (isIdTokenExpired) {
      console.error('Google id token expired');
      handleSignOut();
    } else {
      setIsGoogleSignedIn(true);
    }
  }, []);

  const loadingWithAccessPromptProps = {
    isLoading: true,
    hasError: true,
    errorText: 'Your access to google services (drive etc.) is missing or expired',
    errorDismissButtonText: 'Click here to get access',
    onErrorDismiss: () => {
      handleGoogleAuthorization({
        userEmail,
        setIsGoogleServicesAuthorized,
        setIsAuthorizationPopupBlocked,
        forcePrompt: true,
      });
    },
  };

  if (isTest) return null;

  if (isLoadingGoogleInit) {
    return <FullPageLoader isLoading invisibleBackdrop loadingText="Loading APIs" />;
  }

  if (isGoogleInitError) {
    return <div>Error loading Google APIs</div>;
  }

  return (
    <BrainAuthContext.Provider
      value={{
        hasAuthError,
        handleSignIn,
        handleSignOut,
      }}
    >
      {isGoogleSignedIn && isHealthySignedIn ? (
        <SignOutContext.Provider value={handleSignOut}>
          <AuthenticatedApp />
          {!isGoogleServicesAuthorized && (
            <FullPageLoader
              isLoading
              {...(isAuthorizationPopupBlocked && loadingWithAccessPromptProps)}
            />
          )}
        </SignOutContext.Provider>
      ) : (
        <>
          <UnauthenticatedApp />
          {isGoogleSignedIn && !isHealthySignedIn && <FullPageLoader isLoading />}
        </>
      )}
    </BrainAuthContext.Provider>
  );
}

BrainAuthProvider.propTypes = {
  authenticatedApp: PropTypes.func.isRequired,
  unauthenticatedApp: PropTypes.func.isRequired,
};

export default injectReducerAndSaga({ key: 'auth', reducer })(hot(BrainAuthProvider));
