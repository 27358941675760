import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

import { reporter } from '@shared/reporter';
import { isIntegrationTest } from '@shared/utils/integrationTestUtils';

import { testIdToken, testAccessTokenDecoded } from '../testing/TestUserData';

import {
  ID_TOKEN_COOKIE,
  ID_TOKEN_EXPIRY_COOKIE,
  ACCESS_TOKEN_COOKIE,
  ACCESS_TOKEN_EXPIRY_COOKIE,
} from './constants';

const cookieOptions = {
  secure: true,
  domain: window.location.hostname,
};

if (isIntegrationTest()) {
  Cookies.set(ID_TOKEN_COOKIE, testIdToken, cookieOptions);
  Cookies.set(ACCESS_TOKEN_COOKIE, JSON.stringify(testAccessTokenDecoded), cookieOptions);
}

const clearGoogleAuthCookies = () => {
  Cookies.remove(ID_TOKEN_COOKIE, cookieOptions);
  Cookies.remove(ID_TOKEN_EXPIRY_COOKIE, cookieOptions);
  Cookies.remove(ACCESS_TOKEN_COOKIE, cookieOptions);
  Cookies.remove(ACCESS_TOKEN_EXPIRY_COOKIE, cookieOptions);
};

export const handleGoogleSignOut = () => {
  try {
    clearGoogleAuthCookies();
  } catch (error) {
    console.error(error);
  }
};

const tokenResponseHandler = ({ response, setIsGoogleServicesAuthorized }) => {
  const { access_token: accessToken, expires_in: expiresIn } = response;
  if (!accessToken) {
    reporter.error('Failed to get drive access token from google');
    return;
  }
  const expiresInMs = expiresIn * 1000;
  const expiresTimestamp = Date.now() + expiresInMs;
  // Will be used to set the token in gapi.client.setToken which accepts an object token
  Cookies.set(ACCESS_TOKEN_COOKIE, JSON.stringify(response), cookieOptions);
  Cookies.set(ACCESS_TOKEN_EXPIRY_COOKIE, expiresTimestamp, cookieOptions);

  setIsGoogleServicesAuthorized(true);
  setTimeout(() => {
    setIsGoogleServicesAuthorized(false);
  }, expiresInMs);
};

export const handleGoogleAuthorization = ({
  userEmail,
  setIsGoogleServicesAuthorized,
  setIsAuthorizationPopupBlocked,
  forcePrompt,
}) => {
  const client = window.google.accounts.oauth2.initTokenClient({
    client_id: process.env.GOOGLE_API_CLIENT_ID,
    scope: process.env.GOOGLE_API_SCOPES,
    prompt: forcePrompt ? 'consent' : '',
    hint: userEmail,
    callback: (response) =>
      tokenResponseHandler({ response, setIsGoogleServicesAuthorized }),
    error_callback: (error) => {
      if (error.type === 'popup_failed_to_open') {
        setIsAuthorizationPopupBlocked(true);
      }
    },
  });
  client.requestAccessToken();
};

export const handleGoogleAuthentication = ({ idToken, setIsGoogleSignedIn }) => {
  if (!idToken) throw new Error('Failed to get google id token');

  // exp is a unix timestamp in seconds
  const { exp } = jwtDecode(idToken);
  const expirationTimestamp = exp * 1000;
  Cookies.set(ID_TOKEN_COOKIE, idToken, cookieOptions);
  Cookies.set(ID_TOKEN_EXPIRY_COOKIE, expirationTimestamp, cookieOptions);

  setIsGoogleSignedIn(true);
};
